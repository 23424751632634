import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": true,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "neonPurple",
  "types": ["creative code"],
  "status": ["active"],
  "title": "Creative Code",
  "subtitle": "Learning creative code",
  "description": "",
  "years": ["2022"],
  "institutions": ["nb"],
  "location": "cph",
  "keywords": {
    "topics": ["art"],
    "tools": ["processing"]
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Creative Coder"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Introduction`}</h2>
    <p>{`Here I will showcase various creative code projects.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      